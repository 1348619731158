
    import Vue from "vue";
    import Component from "vue-class-component";
    import { StateChanger } from "vue-infinite-loading";
    import ApiButton from "@/components/ApiButton.vue";
    import SlideUpDown from "vue-slide-up-down";
    import { Watch } from "vue-property-decorator";
    import * as toastr from "toastr";

    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import { UserSearchParameters } from "@/model/UserSearchParameters";
    import { LookupItem, ILookupItem } from "@/model/LookupItem";
    import { RegionLookupItem } from "@/model/RegionLookupItem";
    import { User, IUser } from "@/model/User";
    import { UserRole, UserStatus } from "@/model/Enums";
    import { ISendPasswordResetRequest } from "@/model/ISendPasswordResetRequest";
    import { ISendPasswordResetResponse } from "@/model/ISendPasswordResetResponse";
    import utils from "@/stuff/Utils";

    import UserDialogue from "@/components/UserDialogue.vue";

    @Component({
        components: { ApiButton, SlideUpDown, UserDialogue }
    })
    export default class Users extends Vue {

        //
        // -- lifecycle hooks (https://vuejs.org/v2/guide/instance.html#Instance-Lifecycle-Hooks)
        //

        async mounted() {
            if(this.isBuyerAdmin) {
                this.searchParameters.role = UserRole.Buyer;
                this.searchParameters.buyerIDs = [ +this.$store.state.signedInUser.buyerID ];
                this.searchParameters.buyerID = +this.$store.state.signedInUser.buyerID;

                console.log("*** buyer - parameters = ", this.searchParameters);
            }
            store.dispatch("loadBuyerList");
            store.dispatch("loadSupplierList");
            this.regionList = await apiClient.get("api/region/lookups");
            this.moduleList = await apiClient.get("api/module/lookups");
        }

        beforeDestroy() {
            // If we're using any 3rd party gizmos in a component we need to release
            // any resources(call .destroy, etc.or whatever) to avoid memory leaks.
        }

        //
        // -- properties
        //

        private searchParameters = new UserSearchParameters();
        private totalCount = -1;
        private userList: Array<IUser> = [];
        private moduleList: Array<ILookupItem> = [];

        // computed

        private get fields(): Array<any> {
            return [
                { key: "fullname", label: "Name", sortable: true },
                { key: "email", label: "Email", sortable: true },
                { key: "role", label: "Role", sortable: !this.isBuyerAdmin },
                { key: "created", lable: "Created", sortable: true },
                { key: "organisation", label: "Organisation" },
                { key: "lastLoggedIn", label: "Last sign-in", sortable: true },
                { key: "status", label: "Status", sortable: true },
                { key: "actions", label: "Password" }
            ];
        }

        // we need to do some enforement on the server too!
        private get isBuyerAdmin(): boolean { return store.getters.isBuyerMode; }
        private get isCqmsUser(): boolean { return this.$store.state.signedInUser.role == UserRole.Cqms; }

        private get roleList(): Array<ILookupItem> { return utils.enumToLookups(UserRole); }
        private get roleRadioOptions(): Array<ILookupItem> {
            const anyOption = new LookupItem({ id: 0, description: "All Roles", isArchived: false } as ILookupItem);
            return [anyOption, ...utils.enumToLookups(UserRole).filter((lu: ILookupItem) => lu.id > 0)];
        }

        private get statusList(): Array<ILookupItem> { return utils.enumToLookups(UserStatus); }

        private get buyerList(): Array<ILookupItem> { return this.$store.state.buyerList; }
        private get buyerSearchOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.buyerList, "All Buyers"); }

        private get supplierList(): Array<ILookupItem> { return this.$store.state.supplierList; }
        private get supplierSearchOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.supplierList, "All Suppliers"); }

        private regionList: Array<RegionLookupItem> = [];
        private get regionSearchOptions(): Array<ILookupItem> { 
            const filtered = this.regionList.filter(lu =>
                (!this.isBuyerAdmin && lu.id > 0 && !lu.isArchived && lu.buyerID === this.searchParameters.buyerID) ||
                (this.isBuyerAdmin && lu.id > 0 && !lu.isArchived && this.searchParameters.buyerIDs.indexOf(lu.buyerID) > -1));
            return filtered.length === 0 
                ? [ new LookupItem( { id: 0, description:  "(No regions for this buyer!)", isArchived: false }) ]
                : [ new LookupItem( { id: 0, description:  "Any region", isArchived: false }), ...filtered];
        }

        private get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        //
        // -- watchers
        //

        roleChanging: boolean = false;

        @Watch("searchParameters.role")
        async onRoleChanged() {
            this.roleChanging = true;
            this.searchParameters.buyerID = 0;
            this.searchParameters.supplierID = 0;
            this.searchParameters.regionID = 0;
            await this.refreshSearch();
            this.roleChanging = false;
        }

        @Watch("searchParameters.buyerID")
        async onBuyerChanged() {
            if (!this.roleChanging)
                this.refreshSearch();
        }

        @Watch("searchParameters.supplierID")
        async onSupplierChanged() {
            if (!this.roleChanging)
                this.refreshSearch();
        }

        @Watch("searchParameters.regionID")
        async onRegionChanged() {
            if (!this.roleChanging)
                this.refreshSearch();
        }

        //
        // -- methods
        //

        private rowClass(user: IUser): string {
            return user.deleted ? "deleted hand" : "hand";
        }

        private sendLinkText(user: User): string {
            return user.status == UserStatus.InitialEmailFailed ? "send another link" : "send a link";
        }

        private organisationText(user: User): string {
            if (user.role === UserRole.Buyer && !utils.isEmptyId(user.buyerID)) 
                return this.buyerList.filter(b => b.id == user.buyerID)[0].description;            
            else if (user.role === UserRole.Supplier && !utils.isEmptyId(user.supplierID)) 
                return this.supplierList.filter(s => s.id == user.supplierID)[0].description;            
            else 
                return "- - -";            
        }

        private statusText(user: User): string {
            if(user.status === UserStatus.InitialEmailPending) return "Email pending";
            if(user.status === UserStatus.InitialEmailFailed) return "Email FAILED";
            if(user.status === UserStatus.InitialEmailSent) return "Email sent";
            if(user.status === UserStatus.HasChangedPassword) return "Password changed";
            return "- - -";
        }

        private roleText(user: User): string {
            if(user.role === UserRole.Buyer) {
                if(user.isBuyerHmrcAccess && user.isBuyerAdminAccess) return "Buyer (HMRC & Admin)";
                if(user.isBuyerHmrcAccess) return "Buyer (HMRC)";
                if(user.isBuyerAdminAccess) return "Buyer (Admin)";
                return "Buyer";
            }
            else if (user.isCqmsOrSuperAdmin) {
                if (user.isAuditor && user.isWorkflowAdmin && user.auditorModuleIDs.length == this.moduleList.length) return `${utils.lookupDescription(user.role, this.roleList)} (Auditor  & Workflow Admin)`;
                if (user.isAuditor && user.isWorkflowAdmin && user.auditorModuleIDs.length != this.moduleList.length) return `${utils.lookupDescription(user.role, this.roleList)} (Auditor<b style="color: red">*</b> & Workflow Admin)`;
                if (user.isAuditor && user.auditorModuleIDs.length == this.moduleList.length) return `${utils.lookupDescription(user.role, this.roleList)} (Auditor)`;
                if (user.isAuditor && user.auditorModuleIDs.length != this.moduleList.length) return `${utils.lookupDescription(user.role, this.roleList)} (Auditor<b style="color: red">*</b>)`;
                if (user.isWorkflowAdmin) return `${utils.lookupDescription(user.role, this.roleList)} (Workflow Admin)`;
                return utils.lookupDescription(user.role, this.roleList);
            }
            else {
                return utils.lookupDescription(user.role, this.roleList);
            }
        }

        async sendReminder(user: User) {
            const shouldSend: boolean = await this.$bvModal.msgBoxConfirm(`Do you want to send a reset link to ${user.fullname} (${user.email})?`, {
                title: "Password Reset",
                okVariant: "danger",
                okTitle: "Yes, send!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldSend) return;

            const request: ISendPasswordResetRequest = {
                email: user.email,
                isSelfInitiated: false 
            }
            const response = await apiClient.post("/Api/Authentication/sendPasswordReset", request) as ISendPasswordResetResponse;
            if (response.isSuccess) {
                toastr.success(response.message, "Sent");
            }
            else {
                toastr.error(response.message); // could possibly use message box rather than toast?
            }
        }

        async edit(userData: IUser) {
            const dialog = (this.$refs.userDialogue as UserDialogue);
            dialog.edit(userData);
        }

        editNew() {
            const dialog = (this.$refs.userDialogue as UserDialogue);
            dialog.editNew();
        }

        // can be called from user edit dialogur OR from search results
        showChangePasswordDialogue(user: User) {
            const dialog = (this.$refs.userDialogue as UserDialogue);
            dialog.showChangePasswordDialogue(user);
        }

        refreshSearch(event?: Event) {
            this.userList = [];
            this.searchParameters.pageNumber = 1;
            this.totalCount = -1;
            this.search(event);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            console.log("*** infiniteLoadingHandler");
            console.log(stateChanger);

            if (this.userList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                console.log("*** infiniteLoadingHandler already complete");
                return;
            }
            this.searchParameters.pageNumber += 1;
            console.log("*** infiniteLoadingHandler start search for page " + this.searchParameters.pageNumber );
            await this.search();
            if (this.userList.length >= this.totalCount) {
                stateChanger.complete();
                console.log("*** infiniteLoadingHandler reached count - setting complete");
            }
            else {
                stateChanger.loaded();
                console.log("*** infiniteLoadingHandler setting loaded");
            }           
        }

        private async search(event?: Event) {
            const response = await apiClient.post("/Api/User/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            this.userList.push(...response.list.map((u: IUser) => new User(u)));           
        }

    }
